<template>
	<div class="row">
		<div class="col-12">
			<div class="box">
				<DateRange
					:start.sync="start_date"
					:end.sync="end_date"
					@submit="loadCharges"
					:ShowTodayButton="true"
				/>
			</div>

			<div class="box">
				<h3 class="mb-0">{{ $t('charge.montant_charges') }}</h3>
				<b-table striped hover :items="charges" :fields="fields"></b-table>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Horse from "@/mixins/Horse.js"

	export default {
		name: 'HorseCHarge',
		props: ['horse_id'],
		mixins: [Horse],
		data () {
			return {
				fields:[
                    {key: 'pensions', label: this.getTrad('charge.pensions'), sortable: false},
                    {key: 'invoice_suppliers', label: this.getTrad('charge.invoice_suppliers'), sortable: false},
                ],
				table_busy: false,
				start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
                end_date: new Date(),
				charges: [],
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				await this.loadCharges()
			},

			async loadCharges() {
				let charges = await this.getCharges(this.horse_id, this.start_date, this.end_date)
				charges.invoice_suppliers =  await this.priceFormat(charges.invoice_suppliers,'EUR', true)
				charges.pensions =  await this.priceFormat(charges.pensions,'EUR', true)
				this.charges = [charges]
			}
		},

		watch: {
			
		},

		components: {
            DateRange: () => import('GroomyRoot/components/Inputs/DateRange'),
		}
	}

</script>